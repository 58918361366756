@import "~bootstrap/scss/bootstrap";

.blocklyFlyoutScrollbar[display="none"] {
  display: none;
}

body {
  margin: 0;
  padding: 0;
}

.icon-lofty-component-block {
  fill: #fff !important;
}

.icon-lofty-component-block:hover {
  fill: #75A1D5 !important;
}

html {
  overflow: scroll;
  overflow-x: visible !important;
  -ms-overflow-style: none;
  // scrollbar-width: none;
}

// ::-webkit-scrollbar {
//   display: none;
// }

.sidenav {
  position: fixed;
  top: 0;
  left: 0;
  width: 15%;
  height: 100vh;
  overflow: scroll;
  overflow-x: visible !important;
  -ms-overflow-style: none;
  // scrollbar-width: none;
  background-color: #414141;
  transition: 0.5s;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.05);
  border: 1px solid rgba(0, 0, 0, 0.3);
  .logo {
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
    text-transform: uppercase;
    font-size: 20px;
    font-weight: 700;
  }
  .pages {
    height: 100px;
    overflow: scroll;
    overflow-x: hidden;
  }
}

.main-content {
  position: relative;
  width: 85%;
  left: 15%;
  .navbar {
    padding: 0;
    .container-fluid {
      padding: 0;
    }
  }

  #editor {
    border: 2px solid rgba(0, 0, 0, 0.3);
  }
}

.nav {
  justify-content: space-between;
  .nav-item {
    .btn{
      border-radius: 15px;
    }
  }
}

.gjs-pn-panel {
  position: relative;
}

.gjs-cv-canvas {
  width: 100%;
  height: 100%;
  top: 0;
}

.tab-content {
  display: contents;
}

.icon-flipper-css {
  transform: scaleX(-1);
  -moz-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  -ms-transform: scaleX(-1);
}

.icons-flex {
  background-size: 70% 65% !important;
  height: 22px;
  width: 30px;
  opacity: 0.9;
}

.icon-just-start{
  background: url("../public/img/flex-just-start.png") no-repeat center;
 }
 .icon-just-end{
  background: url("../public/img/flex-just-end.png") no-repeat center;
 }
 .icon-just-sp-bet{
  background: url("../public/img/flex-just-sp-bet.png") no-repeat center;
 }
 .icon-just-sp-ar{
  background: url("../public/img/flex-just-sp-ar.png") no-repeat center;
 }
 .icon-just-sp-cent{
  background: url("../public/img/flex-just-sp-cent.png") no-repeat center;
 }
 .icon-dir-row {
  background: url("../public/img/flex-dir-row.png") no-repeat center;
}
.icon-dir-row-rev {
  background: url("../public/img/flex-dir-row-rev.png") no-repeat center;
}
.icon-dir-col {
  background: url("../public/img/flex-dir-col.png") no-repeat center;
}
.icon-dir-col-rev {
  background: url("../public/img/flex-dir-col-rev.png") no-repeat center;
}
.icon-al-start{
  background: url("../public/img/flex-al-start.png") no-repeat center;
 }
 .icon-al-end{
  background: url("../public/img/flex-al-end.png") no-repeat center;
 }
 .icon-al-str{
  background: url("../public/img/flex-al-str.png") no-repeat center;
 }
 .icon-al-center{
  background: url("../public/img/flex-al-center.png") no-repeat center;
 }

#block {
  height: 100%;
  #blocks {
    height: 100%;

    .gjs-blocks-c {
      align-items: center;
      justify-content: center;
    }
    .gjs-block {
      justify-content: center;
    }
    .gjs-block:hover {
      .icon-lofty-component-block {
        fill: #75A1D5 !important;
      }
      .gjs-block-label {
        color: #75A1D5 !important;
      }
    }
  }
}

/* Theming */
.gjs-one-bg {
  background-color: #525252;
}

.gjs-two-color {
  color: #DADADA;
}

.gjs-three-bg {
  background-color: #990011ff;
  color: #fcf6f5ff;
}

/*
Color hover
.gjs-four-color,
.gjs-four-color-h:hover {
  color: #990011ff;
}
*/

.gjs-pn-btn {
  border: 1px solid white;
}

.gjs-pn-btn:hover {
  border: 1px solid #414141;
}

// Customize Bootstrap CSS
.nav-item,
.nav-link {
  border-radius: 25px;
}

.btn,
.modal-content,
.form-control {
  border-radius: 0 !important;
}

.btn {
  .fa {
    color: #990011ff;
  }
  &:hover {
    .fa {
      color: #fcf6f5ff;
    }
  }
}

/** Error **/
.error {
  .bg-body {
    min-height: 150px;
    max-height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    .title {
      font-weight: 600;
    }
  }
  .btn {
    border-radius: 40px !important;
    padding: 15px 20px;
    font-size: 14px;
    font-weight: 700;
    min-width: 150px;
  }
}

/** Assets **/
/* .gjs-am-meta,
.gjs-am-close,
.gjs-am-file-uploader,
.gjs-am-assets-header {
  display: none;
} */

.gjs-am-file-uploader {
  width: 100%;
}

.gjs-am-assets-cont {
  width: 100%;
}

.gjs-am-assets {
  // justify-content: space-between;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.gjs-am-asset-image {
  margin-top: 1rem;
  margin-bottom: 1rem;
  // display: contents;
  display: flex !important;
  flex-direction: column !important;
  width: 25% !important;
}

.gjs-am-preview {
  background-size: contain !important;
}

.gjs-am-preview-cont {
  margin-top: 1rem;
  width: 100% !important;
  height: 100px !important;
}
.gjs-block-label {
  .fa {
    font-size: 25px;
  }
}
.grp-wrapper {
  background-image: url("data:image/png:base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAIAAADZF8uwAAAAGUlEQVQYV2M4gwH+YwCGIasIUwhT25BVBADtzYNYrHvv4gAAAABJRU5ErkJggg==");
}
.grp-preview {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: crosshair;
}
.grp-handler {
  width: 4px;
  margin-left: -2px;
  user-select: none;
  height: 100%;
  -webkit-user-select: none;
  -moz-user-select: none;
}

.grp-handler-close {
  color: rgba(0, 0, 0, 0.4);
  border-radius: 0 2px 10px rgba(0, 0, 0, 0.25);
  background-color: #fff;
  text-align: center;
  width: 15px;
  height: 15px;
  margin-left: -5px;
  line-height: 10px;
  font-size: 21px;
  cursor: pointer;
}

.grp-handler-close {
  position: absolute;
  top: -17px;
}

.grp-handler-drag {
  background-color: rgba(0, 0, 0, 0.5);
  cursor: col-resize;
  width: 100%;
  height: 100%;
}

.grp-handler-selected .grap-handler-drag {
  background-color: rgba(255, 255, 255, 0.5);
}

.grp-handler-cp-c {
  display: none;
}

.grp-handler-selected .grp-handler-cp-c {
  display: block;
}

.grp-handler-cp-wrap {
  width: 15px;
  height: 15px;
  margin-left: -8px;
  border: 3px solid #fff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.25);
  overflow: hidden;
  border-radius: 100%;
  cursor: pointer;
}

.grp-handler-cp-wrap input[type="color"] {
  opacity: 0;
  cursor: pointer;
}

.blocklyDropDownDiv {
  z-index: 1200;
}

.blocklyTreeRow {
  cursor: pointer;
}